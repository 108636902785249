/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import type { Configuration } from '../configuration';
import type { AxiosPromise, AxiosInstance, RawAxiosRequestConfig } from 'axios';
import globalAxios from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, type RequestArgs, BaseAPI, RequiredError, operationServerMap } from '../base';
// @ts-ignore
import type { ConsumerDto } from '../models';
// @ts-ignore
import type { ProblemDetail } from '../models';
// @ts-ignore
import type { UpdateConsumerDto } from '../models';
/**
 * MeControllerApi - axios parameter creator
 * @export
 */
export const MeControllerApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         *
         * @param {string} otp
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        completePhoneUpdate: async (otp: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'otp' is not null or undefined
            assertParamExists('completePhoneUpdate', 'otp', otp)
            const localVarPath = `/me/phone/verify`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (otp !== undefined) {
                localVarQueryParameter['otp'] = otp;
            }



            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @param {string} otp
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        completePhoneVerification: async (otp: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'otp' is not null or undefined
            assertParamExists('completePhoneVerification', 'otp', otp)
            const localVarPath = `/me/phone/verify`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (otp !== undefined) {
                localVarQueryParameter['otp'] = otp;
            }



            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMe: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/me`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;



            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @param {string} phone
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        initiatePhoneUpdate: async (phone: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'phone' is not null or undefined
            assertParamExists('initiatePhoneUpdate', 'phone', phone)
            const localVarPath = `/me/phone`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (phone !== undefined) {
                localVarQueryParameter['phone'] = phone;
            }



            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @param {string} phone
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        initiatePhoneVerification: async (phone: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'phone' is not null or undefined
            assertParamExists('initiatePhoneVerification', 'phone', phone)
            const localVarPath = `/me/phone`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (phone !== undefined) {
                localVarQueryParameter['phone'] = phone;
            }



            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        removeAccount: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/me`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;



            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @param {string} authorization
         * @param {string} code
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateEmail: async (authorization: string, code: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'authorization' is not null or undefined
            assertParamExists('updateEmail', 'authorization', authorization)
            // verify required parameter 'code' is not null or undefined
            assertParamExists('updateEmail', 'code', code)
            const localVarPath = `/me/email`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (code !== undefined) {
                localVarQueryParameter['code'] = code;
            }

            if (authorization != null) {
                localVarHeaderParameter['Authorization'] = String(authorization);
            }



            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateLastSignIn: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/me/sign-in`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;



            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @param {UpdateConsumerDto} updateConsumerDto
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updatePersonalData: async (updateConsumerDto: UpdateConsumerDto, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'updateConsumerDto' is not null or undefined
            assertParamExists('updatePersonalData', 'updateConsumerDto', updateConsumerDto)
            const localVarPath = `/me`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;



            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateConsumerDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * MeControllerApi - functional programming interface
 * @export
 */
export const MeControllerApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = MeControllerApiAxiosParamCreator(configuration)
    return {
        /**
         *
         * @param {string} otp
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async completePhoneUpdate(otp: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ConsumerDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.completePhoneUpdate(otp, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['MeControllerApi.completePhoneUpdate']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         *
         * @param {string} otp
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async completePhoneVerification(otp: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.completePhoneVerification(otp, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['MeControllerApi.completePhoneVerification']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         *
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getMe(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ConsumerDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getMe(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['MeControllerApi.getMe']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         *
         * @param {string} phone
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async initiatePhoneUpdate(phone: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ConsumerDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.initiatePhoneUpdate(phone, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['MeControllerApi.initiatePhoneUpdate']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         *
         * @param {string} phone
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async initiatePhoneVerification(phone: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.initiatePhoneVerification(phone, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['MeControllerApi.initiatePhoneVerification']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         *
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async removeAccount(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.removeAccount(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['MeControllerApi.removeAccount']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         *
         * @param {string} authorization
         * @param {string} code
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateEmail(authorization: string, code: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ConsumerDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateEmail(authorization, code, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['MeControllerApi.updateEmail']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         *
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateLastSignIn(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateLastSignIn(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['MeControllerApi.updateLastSignIn']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         *
         * @param {UpdateConsumerDto} updateConsumerDto
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updatePersonalData(updateConsumerDto: UpdateConsumerDto, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ConsumerDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updatePersonalData(updateConsumerDto, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['MeControllerApi.updatePersonalData']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * MeControllerApi - factory interface
 * @export
 */
export const MeControllerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = MeControllerApiFp(configuration)
    return {
        /**
         *
         * @param {MeControllerApiCompletePhoneUpdateRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        completePhoneUpdate(requestParameters: MeControllerApiCompletePhoneUpdateRequest, options?: RawAxiosRequestConfig): AxiosPromise<ConsumerDto> {
            return localVarFp.completePhoneUpdate(requestParameters.otp, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @param {MeControllerApiCompletePhoneVerificationRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        completePhoneVerification(requestParameters: MeControllerApiCompletePhoneVerificationRequest, options?: RawAxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.completePhoneVerification(requestParameters.otp, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMe(options?: RawAxiosRequestConfig): AxiosPromise<ConsumerDto> {
            return localVarFp.getMe(options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @param {MeControllerApiInitiatePhoneUpdateRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        initiatePhoneUpdate(requestParameters: MeControllerApiInitiatePhoneUpdateRequest, options?: RawAxiosRequestConfig): AxiosPromise<ConsumerDto> {
            return localVarFp.initiatePhoneUpdate(requestParameters.phone, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @param {MeControllerApiInitiatePhoneVerificationRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        initiatePhoneVerification(requestParameters: MeControllerApiInitiatePhoneVerificationRequest, options?: RawAxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.initiatePhoneVerification(requestParameters.phone, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        removeAccount(options?: RawAxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.removeAccount(options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @param {MeControllerApiUpdateEmailRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateEmail(requestParameters: MeControllerApiUpdateEmailRequest, options?: RawAxiosRequestConfig): AxiosPromise<ConsumerDto> {
            return localVarFp.updateEmail(requestParameters.authorization, requestParameters.code, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateLastSignIn(options?: RawAxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.updateLastSignIn(options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @param {MeControllerApiUpdatePersonalDataRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updatePersonalData(requestParameters: MeControllerApiUpdatePersonalDataRequest, options?: RawAxiosRequestConfig): AxiosPromise<ConsumerDto> {
            return localVarFp.updatePersonalData(requestParameters.updateConsumerDto, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for completePhoneUpdate operation in MeControllerApi.
 * @export
 * @interface MeControllerApiCompletePhoneUpdateRequest
 */
export interface MeControllerApiCompletePhoneUpdateRequest {
    /**
     *
     * @type {string}
     * @memberof MeControllerApiCompletePhoneUpdate
     */
    readonly otp: string
}

/**
 * Request parameters for completePhoneVerification operation in MeControllerApi.
 * @export
 * @interface MeControllerApiCompletePhoneVerificationRequest
 */
export interface MeControllerApiCompletePhoneVerificationRequest {
    /**
     *
     * @type {string}
     * @memberof MeControllerApiCompletePhoneVerification
     */
    readonly otp: string
}

/**
 * Request parameters for initiatePhoneUpdate operation in MeControllerApi.
 * @export
 * @interface MeControllerApiInitiatePhoneUpdateRequest
 */
export interface MeControllerApiInitiatePhoneUpdateRequest {
    /**
     *
     * @type {string}
     * @memberof MeControllerApiInitiatePhoneUpdate
     */
    readonly phone: string
}

/**
 * Request parameters for initiatePhoneVerification operation in MeControllerApi.
 * @export
 * @interface MeControllerApiInitiatePhoneVerificationRequest
 */
export interface MeControllerApiInitiatePhoneVerificationRequest {
    /**
     *
     * @type {string}
     * @memberof MeControllerApiInitiatePhoneVerification
     */
    readonly phone: string
}

/**
 * Request parameters for updateEmail operation in MeControllerApi.
 * @export
 * @interface MeControllerApiUpdateEmailRequest
 */
export interface MeControllerApiUpdateEmailRequest {
    /**
     *
     * @type {string}
     * @memberof MeControllerApiUpdateEmail
     */
    readonly authorization: string

    /**
     *
     * @type {string}
     * @memberof MeControllerApiUpdateEmail
     */
    readonly code: string
}

/**
 * Request parameters for updatePersonalData operation in MeControllerApi.
 * @export
 * @interface MeControllerApiUpdatePersonalDataRequest
 */
export interface MeControllerApiUpdatePersonalDataRequest {
    /**
     *
     * @type {UpdateConsumerDto}
     * @memberof MeControllerApiUpdatePersonalData
     */
    readonly updateConsumerDto: UpdateConsumerDto
}

/**
 * MeControllerApi - object-oriented interface
 * @export
 * @class MeControllerApi
 * @extends {BaseAPI}
 */
export class MeControllerApi extends BaseAPI {
    /**
     *
     * @param {MeControllerApiCompletePhoneUpdateRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MeControllerApi
     */
    public completePhoneUpdate(requestParameters: MeControllerApiCompletePhoneUpdateRequest, options?: RawAxiosRequestConfig) {
        return MeControllerApiFp(this.configuration).completePhoneUpdate(requestParameters.otp, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     *
     * @param {MeControllerApiCompletePhoneVerificationRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MeControllerApi
     */
    public completePhoneVerification(requestParameters: MeControllerApiCompletePhoneVerificationRequest, options?: RawAxiosRequestConfig) {
        return MeControllerApiFp(this.configuration).completePhoneVerification(requestParameters.otp, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MeControllerApi
     */
    public getMe(options?: RawAxiosRequestConfig) {
        return MeControllerApiFp(this.configuration).getMe(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     *
     * @param {MeControllerApiInitiatePhoneUpdateRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MeControllerApi
     */
    public initiatePhoneUpdate(requestParameters: MeControllerApiInitiatePhoneUpdateRequest, options?: RawAxiosRequestConfig) {
        return MeControllerApiFp(this.configuration).initiatePhoneUpdate(requestParameters.phone, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     *
     * @param {MeControllerApiInitiatePhoneVerificationRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MeControllerApi
     */
    public initiatePhoneVerification(requestParameters: MeControllerApiInitiatePhoneVerificationRequest, options?: RawAxiosRequestConfig) {
        return MeControllerApiFp(this.configuration).initiatePhoneVerification(requestParameters.phone, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MeControllerApi
     */
    public removeAccount(options?: RawAxiosRequestConfig) {
        return MeControllerApiFp(this.configuration).removeAccount(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     *
     * @param {MeControllerApiUpdateEmailRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MeControllerApi
     */
    public updateEmail(requestParameters: MeControllerApiUpdateEmailRequest, options?: RawAxiosRequestConfig) {
        return MeControllerApiFp(this.configuration).updateEmail(requestParameters.authorization, requestParameters.code, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MeControllerApi
     */
    public updateLastSignIn(options?: RawAxiosRequestConfig) {
        return MeControllerApiFp(this.configuration).updateLastSignIn(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     *
     * @param {MeControllerApiUpdatePersonalDataRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MeControllerApi
     */
    public updatePersonalData(requestParameters: MeControllerApiUpdatePersonalDataRequest, options?: RawAxiosRequestConfig) {
        return MeControllerApiFp(this.configuration).updatePersonalData(requestParameters.updateConsumerDto, options).then((request) => request(this.axios, this.basePath));
    }
}

