/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import type { Configuration } from '../configuration';
import type { AxiosPromise, AxiosInstance, RawAxiosRequestConfig } from 'axios';
import globalAxios from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, type RequestArgs, BaseAPI, RequiredError, operationServerMap } from '../base';
// @ts-ignore
import type { PointsConversionEstimationDto } from '../models';
// @ts-ignore
import type { PointsConversionRequestResolutionDto } from '../models';
// @ts-ignore
import type { ProblemDetail } from '../models';
/**
 * PointsConversionControllerApi - axios parameter creator
 * @export
 */
export const PointsConversionControllerApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         *
         * @param {string} conversionRequestId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPointsConversionEstimation: async (conversionRequestId: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'conversionRequestId' is not null or undefined
            assertParamExists('getPointsConversionEstimation', 'conversionRequestId', conversionRequestId)
            const localVarPath = `/points-conversion/{conversionRequestId}/estimation`
                .replace(`{${"conversionRequestId"}}`, encodeURIComponent(String(conversionRequestId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;



            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @param {string} conversionRequestId
         * @param {PointsConversionRequestResolutionDto} pointsConversionRequestResolutionDto
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        resolvePendingConversionRequest: async (conversionRequestId: string, pointsConversionRequestResolutionDto: PointsConversionRequestResolutionDto, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'conversionRequestId' is not null or undefined
            assertParamExists('resolvePendingConversionRequest', 'conversionRequestId', conversionRequestId)
            // verify required parameter 'pointsConversionRequestResolutionDto' is not null or undefined
            assertParamExists('resolvePendingConversionRequest', 'pointsConversionRequestResolutionDto', pointsConversionRequestResolutionDto)
            const localVarPath = `/points-conversion/{conversionRequestId}`
                .replace(`{${"conversionRequestId"}}`, encodeURIComponent(String(conversionRequestId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;



            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(pointsConversionRequestResolutionDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * PointsConversionControllerApi - functional programming interface
 * @export
 */
export const PointsConversionControllerApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = PointsConversionControllerApiAxiosParamCreator(configuration)
    return {
        /**
         *
         * @param {string} conversionRequestId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getPointsConversionEstimation(conversionRequestId: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PointsConversionEstimationDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getPointsConversionEstimation(conversionRequestId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['PointsConversionControllerApi.getPointsConversionEstimation']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         *
         * @param {string} conversionRequestId
         * @param {PointsConversionRequestResolutionDto} pointsConversionRequestResolutionDto
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async resolvePendingConversionRequest(conversionRequestId: string, pointsConversionRequestResolutionDto: PointsConversionRequestResolutionDto, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.resolvePendingConversionRequest(conversionRequestId, pointsConversionRequestResolutionDto, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['PointsConversionControllerApi.resolvePendingConversionRequest']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * PointsConversionControllerApi - factory interface
 * @export
 */
export const PointsConversionControllerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = PointsConversionControllerApiFp(configuration)
    return {
        /**
         *
         * @param {PointsConversionControllerApiGetPointsConversionEstimationRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPointsConversionEstimation(requestParameters: PointsConversionControllerApiGetPointsConversionEstimationRequest, options?: RawAxiosRequestConfig): AxiosPromise<PointsConversionEstimationDto> {
            return localVarFp.getPointsConversionEstimation(requestParameters.conversionRequestId, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @param {PointsConversionControllerApiResolvePendingConversionRequestRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        resolvePendingConversionRequest(requestParameters: PointsConversionControllerApiResolvePendingConversionRequestRequest, options?: RawAxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.resolvePendingConversionRequest(requestParameters.conversionRequestId, requestParameters.pointsConversionRequestResolutionDto, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for getPointsConversionEstimation operation in PointsConversionControllerApi.
 * @export
 * @interface PointsConversionControllerApiGetPointsConversionEstimationRequest
 */
export interface PointsConversionControllerApiGetPointsConversionEstimationRequest {
    /**
     *
     * @type {string}
     * @memberof PointsConversionControllerApiGetPointsConversionEstimation
     */
    readonly conversionRequestId: string
}

/**
 * Request parameters for resolvePendingConversionRequest operation in PointsConversionControllerApi.
 * @export
 * @interface PointsConversionControllerApiResolvePendingConversionRequestRequest
 */
export interface PointsConversionControllerApiResolvePendingConversionRequestRequest {
    /**
     *
     * @type {string}
     * @memberof PointsConversionControllerApiResolvePendingConversionRequest
     */
    readonly conversionRequestId: string

    /**
     *
     * @type {PointsConversionRequestResolutionDto}
     * @memberof PointsConversionControllerApiResolvePendingConversionRequest
     */
    readonly pointsConversionRequestResolutionDto: PointsConversionRequestResolutionDto
}

/**
 * PointsConversionControllerApi - object-oriented interface
 * @export
 * @class PointsConversionControllerApi
 * @extends {BaseAPI}
 */
export class PointsConversionControllerApi extends BaseAPI {
    /**
     *
     * @param {PointsConversionControllerApiGetPointsConversionEstimationRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PointsConversionControllerApi
     */
    public getPointsConversionEstimation(requestParameters: PointsConversionControllerApiGetPointsConversionEstimationRequest, options?: RawAxiosRequestConfig) {
        return PointsConversionControllerApiFp(this.configuration).getPointsConversionEstimation(requestParameters.conversionRequestId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     *
     * @param {PointsConversionControllerApiResolvePendingConversionRequestRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PointsConversionControllerApi
     */
    public resolvePendingConversionRequest(requestParameters: PointsConversionControllerApiResolvePendingConversionRequestRequest, options?: RawAxiosRequestConfig) {
        return PointsConversionControllerApiFp(this.configuration).resolvePendingConversionRequest(requestParameters.conversionRequestId, requestParameters.pointsConversionRequestResolutionDto, options).then((request) => request(this.axios, this.basePath));
    }
}

