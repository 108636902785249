/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import type { Configuration } from '../configuration';
import type { AxiosPromise, AxiosInstance, RawAxiosRequestConfig } from 'axios';
import globalAxios from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, type RequestArgs, BaseAPI, RequiredError, operationServerMap } from '../base';
// @ts-ignore
import type { MemberImportDto } from '../models';
// @ts-ignore
import type { MemberImportRequestDto } from '../models';
// @ts-ignore
import type { PagedMemberImportDiscrepancyDto } from '../models';
// @ts-ignore
import type { PagedMemberImportDto } from '../models';
// @ts-ignore
import type { ProblemDetail } from '../models';
/**
 * MemberImportRequestControllerApi - axios parameter creator
 * @export
 */
export const MemberImportRequestControllerApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         *
         * @param {string} id
         * @param {number} [page] Zero-based page index (0..N)
         * @param {number} [size] The size of the page to be returned
         * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDiscrepancies: async (id: string, page?: number, size?: number, sort?: Array<string>, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getDiscrepancies', 'id', id)
            const localVarPath = `/member-import-request/{id}/discrepancies`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (size !== undefined) {
                localVarQueryParameter['size'] = size;
            }

            if (sort) {
                localVarQueryParameter['sort'] = sort;
            }



            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @param {number} [page] Zero-based page index (0..N)
         * @param {number} [size] The size of the page to be returned
         * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMemberImportRequests: async (page?: number, size?: number, sort?: Array<string>, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/member-import-request`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (size !== undefined) {
                localVarQueryParameter['size'] = size;
            }

            if (sort) {
                localVarQueryParameter['sort'] = sort;
            }



            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @param {MemberImportRequestDto} memberImportRequestDto
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        requestImport: async (memberImportRequestDto: MemberImportRequestDto, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'memberImportRequestDto' is not null or undefined
            assertParamExists('requestImport', 'memberImportRequestDto', memberImportRequestDto)
            const localVarPath = `/member-import-request`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;



            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(memberImportRequestDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * MemberImportRequestControllerApi - functional programming interface
 * @export
 */
export const MemberImportRequestControllerApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = MemberImportRequestControllerApiAxiosParamCreator(configuration)
    return {
        /**
         *
         * @param {string} id
         * @param {number} [page] Zero-based page index (0..N)
         * @param {number} [size] The size of the page to be returned
         * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getDiscrepancies(id: string, page?: number, size?: number, sort?: Array<string>, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PagedMemberImportDiscrepancyDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getDiscrepancies(id, page, size, sort, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['MemberImportRequestControllerApi.getDiscrepancies']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         *
         * @param {number} [page] Zero-based page index (0..N)
         * @param {number} [size] The size of the page to be returned
         * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getMemberImportRequests(page?: number, size?: number, sort?: Array<string>, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PagedMemberImportDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getMemberImportRequests(page, size, sort, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['MemberImportRequestControllerApi.getMemberImportRequests']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         *
         * @param {MemberImportRequestDto} memberImportRequestDto
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async requestImport(memberImportRequestDto: MemberImportRequestDto, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<MemberImportDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.requestImport(memberImportRequestDto, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['MemberImportRequestControllerApi.requestImport']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * MemberImportRequestControllerApi - factory interface
 * @export
 */
export const MemberImportRequestControllerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = MemberImportRequestControllerApiFp(configuration)
    return {
        /**
         *
         * @param {MemberImportRequestControllerApiGetDiscrepanciesRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDiscrepancies(requestParameters: MemberImportRequestControllerApiGetDiscrepanciesRequest, options?: RawAxiosRequestConfig): AxiosPromise<PagedMemberImportDiscrepancyDto> {
            return localVarFp.getDiscrepancies(requestParameters.id, requestParameters.page, requestParameters.size, requestParameters.sort, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @param {MemberImportRequestControllerApiGetMemberImportRequestsRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMemberImportRequests(requestParameters: MemberImportRequestControllerApiGetMemberImportRequestsRequest = {}, options?: RawAxiosRequestConfig): AxiosPromise<PagedMemberImportDto> {
            return localVarFp.getMemberImportRequests(requestParameters.page, requestParameters.size, requestParameters.sort, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @param {MemberImportRequestControllerApiRequestImportRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        requestImport(requestParameters: MemberImportRequestControllerApiRequestImportRequest, options?: RawAxiosRequestConfig): AxiosPromise<MemberImportDto> {
            return localVarFp.requestImport(requestParameters.memberImportRequestDto, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for getDiscrepancies operation in MemberImportRequestControllerApi.
 * @export
 * @interface MemberImportRequestControllerApiGetDiscrepanciesRequest
 */
export interface MemberImportRequestControllerApiGetDiscrepanciesRequest {
    /**
     *
     * @type {string}
     * @memberof MemberImportRequestControllerApiGetDiscrepancies
     */
    readonly id: string

    /**
     * Zero-based page index (0..N)
     * @type {number}
     * @memberof MemberImportRequestControllerApiGetDiscrepancies
     */
    readonly page?: number

    /**
     * The size of the page to be returned
     * @type {number}
     * @memberof MemberImportRequestControllerApiGetDiscrepancies
     */
    readonly size?: number

    /**
     * Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
     * @type {Array<string>}
     * @memberof MemberImportRequestControllerApiGetDiscrepancies
     */
    readonly sort?: Array<string>
}

/**
 * Request parameters for getMemberImportRequests operation in MemberImportRequestControllerApi.
 * @export
 * @interface MemberImportRequestControllerApiGetMemberImportRequestsRequest
 */
export interface MemberImportRequestControllerApiGetMemberImportRequestsRequest {
    /**
     * Zero-based page index (0..N)
     * @type {number}
     * @memberof MemberImportRequestControllerApiGetMemberImportRequests
     */
    readonly page?: number

    /**
     * The size of the page to be returned
     * @type {number}
     * @memberof MemberImportRequestControllerApiGetMemberImportRequests
     */
    readonly size?: number

    /**
     * Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
     * @type {Array<string>}
     * @memberof MemberImportRequestControllerApiGetMemberImportRequests
     */
    readonly sort?: Array<string>
}

/**
 * Request parameters for requestImport operation in MemberImportRequestControllerApi.
 * @export
 * @interface MemberImportRequestControllerApiRequestImportRequest
 */
export interface MemberImportRequestControllerApiRequestImportRequest {
    /**
     *
     * @type {MemberImportRequestDto}
     * @memberof MemberImportRequestControllerApiRequestImport
     */
    readonly memberImportRequestDto: MemberImportRequestDto
}

/**
 * MemberImportRequestControllerApi - object-oriented interface
 * @export
 * @class MemberImportRequestControllerApi
 * @extends {BaseAPI}
 */
export class MemberImportRequestControllerApi extends BaseAPI {
    /**
     *
     * @param {MemberImportRequestControllerApiGetDiscrepanciesRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MemberImportRequestControllerApi
     */
    public getDiscrepancies(requestParameters: MemberImportRequestControllerApiGetDiscrepanciesRequest, options?: RawAxiosRequestConfig) {
        return MemberImportRequestControllerApiFp(this.configuration).getDiscrepancies(requestParameters.id, requestParameters.page, requestParameters.size, requestParameters.sort, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     *
     * @param {MemberImportRequestControllerApiGetMemberImportRequestsRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MemberImportRequestControllerApi
     */
    public getMemberImportRequests(requestParameters: MemberImportRequestControllerApiGetMemberImportRequestsRequest = {}, options?: RawAxiosRequestConfig) {
        return MemberImportRequestControllerApiFp(this.configuration).getMemberImportRequests(requestParameters.page, requestParameters.size, requestParameters.sort, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     *
     * @param {MemberImportRequestControllerApiRequestImportRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MemberImportRequestControllerApi
     */
    public requestImport(requestParameters: MemberImportRequestControllerApiRequestImportRequest, options?: RawAxiosRequestConfig) {
        return MemberImportRequestControllerApiFp(this.configuration).requestImport(requestParameters.memberImportRequestDto, options).then((request) => request(this.axios, this.basePath));
    }
}

