/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */



/**
 * 
 * @export
 * @interface TimePeriod
 */
export interface TimePeriod {
    /**
     * 
     * @type {string}
     * @memberof TimePeriod
     */
    'openDay'?: TimePeriodOpenDayEnum;
    /**
     * 
     * @type {string}
     * @memberof TimePeriod
     */
    'openTime'?: string;
    /**
     * 
     * @type {string}
     * @memberof TimePeriod
     */
    'closeDay'?: TimePeriodCloseDayEnum;
    /**
     * 
     * @type {string}
     * @memberof TimePeriod
     */
    'closeTime'?: string;
}

/**
    * @export
    * @enum {string}
    */
export enum TimePeriodOpenDayEnum {
    MONDAY = 'MONDAY',
    TUESDAY = 'TUESDAY',
    WEDNESDAY = 'WEDNESDAY',
    THURSDAY = 'THURSDAY',
    FRIDAY = 'FRIDAY',
    SATURDAY = 'SATURDAY',
    SUNDAY = 'SUNDAY'
}
/**
    * @export
    * @enum {string}
    */
export enum TimePeriodCloseDayEnum {
    MONDAY = 'MONDAY',
    TUESDAY = 'TUESDAY',
    WEDNESDAY = 'WEDNESDAY',
    THURSDAY = 'THURSDAY',
    FRIDAY = 'FRIDAY',
    SATURDAY = 'SATURDAY',
    SUNDAY = 'SUNDAY'
}


