/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import type { Configuration } from '../configuration';
import type { AxiosPromise, AxiosInstance, RawAxiosRequestConfig } from 'axios';
import globalAxios from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, type RequestArgs, BaseAPI, RequiredError, operationServerMap } from '../base';
// @ts-ignore
import type { ConsumerDto } from '../models';
// @ts-ignore
import type { ConsumerDtoDefaultView } from '../models';
// @ts-ignore
import type { ProblemDetail } from '../models';
// @ts-ignore
import type { UpdateGhostConsumerDto } from '../models';
/**
 * ConsumerControllerApi - axios parameter creator
 * @export
 */
export const ConsumerControllerApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         *
         * @param {string} [email]
         * @param {string} [phone]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getInvitationCandidates: async (email?: string, phone?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/consumers/invitation-candidates`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (email !== undefined) {
                localVarQueryParameter['email'] = email;
            }

            if (phone !== undefined) {
                localVarQueryParameter['phone'] = phone;
            }



            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @param {string} id
         * @param {UpdateGhostConsumerDto} updateGhostConsumerDto
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateAccount: async (id: string, updateGhostConsumerDto: UpdateGhostConsumerDto, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('updateAccount', 'id', id)
            // verify required parameter 'updateGhostConsumerDto' is not null or undefined
            assertParamExists('updateAccount', 'updateGhostConsumerDto', updateGhostConsumerDto)
            const localVarPath = `/consumers/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;



            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateGhostConsumerDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ConsumerControllerApi - functional programming interface
 * @export
 */
export const ConsumerControllerApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ConsumerControllerApiAxiosParamCreator(configuration)
    return {
        /**
         *
         * @param {string} [email]
         * @param {string} [phone]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getInvitationCandidates(email?: string, phone?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ConsumerDtoDefaultView>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getInvitationCandidates(email, phone, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ConsumerControllerApi.getInvitationCandidates']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         *
         * @param {string} id
         * @param {UpdateGhostConsumerDto} updateGhostConsumerDto
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateAccount(id: string, updateGhostConsumerDto: UpdateGhostConsumerDto, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ConsumerDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateAccount(id, updateGhostConsumerDto, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ConsumerControllerApi.updateAccount']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * ConsumerControllerApi - factory interface
 * @export
 */
export const ConsumerControllerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ConsumerControllerApiFp(configuration)
    return {
        /**
         *
         * @param {ConsumerControllerApiGetInvitationCandidatesRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getInvitationCandidates(requestParameters: ConsumerControllerApiGetInvitationCandidatesRequest = {}, options?: RawAxiosRequestConfig): AxiosPromise<ConsumerDtoDefaultView> {
            return localVarFp.getInvitationCandidates(requestParameters.email, requestParameters.phone, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @param {ConsumerControllerApiUpdateAccountRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateAccount(requestParameters: ConsumerControllerApiUpdateAccountRequest, options?: RawAxiosRequestConfig): AxiosPromise<ConsumerDto> {
            return localVarFp.updateAccount(requestParameters.id, requestParameters.updateGhostConsumerDto, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for getInvitationCandidates operation in ConsumerControllerApi.
 * @export
 * @interface ConsumerControllerApiGetInvitationCandidatesRequest
 */
export interface ConsumerControllerApiGetInvitationCandidatesRequest {
    /**
     *
     * @type {string}
     * @memberof ConsumerControllerApiGetInvitationCandidates
     */
    readonly email?: string

    /**
     *
     * @type {string}
     * @memberof ConsumerControllerApiGetInvitationCandidates
     */
    readonly phone?: string
}

/**
 * Request parameters for updateAccount operation in ConsumerControllerApi.
 * @export
 * @interface ConsumerControllerApiUpdateAccountRequest
 */
export interface ConsumerControllerApiUpdateAccountRequest {
    /**
     *
     * @type {string}
     * @memberof ConsumerControllerApiUpdateAccount
     */
    readonly id: string

    /**
     *
     * @type {UpdateGhostConsumerDto}
     * @memberof ConsumerControllerApiUpdateAccount
     */
    readonly updateGhostConsumerDto: UpdateGhostConsumerDto
}

/**
 * ConsumerControllerApi - object-oriented interface
 * @export
 * @class ConsumerControllerApi
 * @extends {BaseAPI}
 */
export class ConsumerControllerApi extends BaseAPI {
    /**
     *
     * @param {ConsumerControllerApiGetInvitationCandidatesRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ConsumerControllerApi
     */
    public getInvitationCandidates(requestParameters: ConsumerControllerApiGetInvitationCandidatesRequest = {}, options?: RawAxiosRequestConfig) {
        return ConsumerControllerApiFp(this.configuration).getInvitationCandidates(requestParameters.email, requestParameters.phone, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     *
     * @param {ConsumerControllerApiUpdateAccountRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ConsumerControllerApi
     */
    public updateAccount(requestParameters: ConsumerControllerApiUpdateAccountRequest, options?: RawAxiosRequestConfig) {
        return ConsumerControllerApiFp(this.configuration).updateAccount(requestParameters.id, requestParameters.updateGhostConsumerDto, options).then((request) => request(this.axios, this.basePath));
    }
}

