/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import type { Configuration } from '../configuration';
import type { AxiosPromise, AxiosInstance, RawAxiosRequestConfig } from 'axios';
import globalAxios from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, type RequestArgs, BaseAPI, RequiredError, operationServerMap } from '../base';
// @ts-ignore
import type { ProblemDetail } from '../models';
/**
 * CampaignEmailSubscriptionControllerApi - axios parameter creator
 * @export
 */
export const CampaignEmailSubscriptionControllerApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         *
         * @param {string} externalId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMemberEmailSubscriptionStatus: async (externalId: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'externalId' is not null or undefined
            assertParamExists('getMemberEmailSubscriptionStatus', 'externalId', externalId)
            const localVarPath = `/campaigns/members/email-subscription/{externalId}`
                .replace(`{${"externalId"}}`, encodeURIComponent(String(externalId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;



            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @param {string} externalId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        memberEmailUnsubscribe: async (externalId: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'externalId' is not null or undefined
            assertParamExists('memberEmailUnsubscribe', 'externalId', externalId)
            const localVarPath = `/campaigns/members/email-subscription/{externalId}/one-click-unsubscribe`
                .replace(`{${"externalId"}}`, encodeURIComponent(String(externalId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;



            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @param {string} externalId
         * @param {boolean} status
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        setMemberEmailSubscriptionStatus: async (externalId: string, status: boolean, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'externalId' is not null or undefined
            assertParamExists('setMemberEmailSubscriptionStatus', 'externalId', externalId)
            // verify required parameter 'status' is not null or undefined
            assertParamExists('setMemberEmailSubscriptionStatus', 'status', status)
            const localVarPath = `/campaigns/members/email-subscription/{externalId}`
                .replace(`{${"externalId"}}`, encodeURIComponent(String(externalId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (status !== undefined) {
                localVarQueryParameter['status'] = status;
            }



            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * CampaignEmailSubscriptionControllerApi - functional programming interface
 * @export
 */
export const CampaignEmailSubscriptionControllerApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = CampaignEmailSubscriptionControllerApiAxiosParamCreator(configuration)
    return {
        /**
         *
         * @param {string} externalId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getMemberEmailSubscriptionStatus(externalId: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<boolean>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getMemberEmailSubscriptionStatus(externalId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['CampaignEmailSubscriptionControllerApi.getMemberEmailSubscriptionStatus']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         *
         * @param {string} externalId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async memberEmailUnsubscribe(externalId: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.memberEmailUnsubscribe(externalId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['CampaignEmailSubscriptionControllerApi.memberEmailUnsubscribe']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         *
         * @param {string} externalId
         * @param {boolean} status
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async setMemberEmailSubscriptionStatus(externalId: string, status: boolean, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.setMemberEmailSubscriptionStatus(externalId, status, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['CampaignEmailSubscriptionControllerApi.setMemberEmailSubscriptionStatus']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * CampaignEmailSubscriptionControllerApi - factory interface
 * @export
 */
export const CampaignEmailSubscriptionControllerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = CampaignEmailSubscriptionControllerApiFp(configuration)
    return {
        /**
         *
         * @param {CampaignEmailSubscriptionControllerApiGetMemberEmailSubscriptionStatusRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMemberEmailSubscriptionStatus(requestParameters: CampaignEmailSubscriptionControllerApiGetMemberEmailSubscriptionStatusRequest, options?: RawAxiosRequestConfig): AxiosPromise<boolean> {
            return localVarFp.getMemberEmailSubscriptionStatus(requestParameters.externalId, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @param {CampaignEmailSubscriptionControllerApiMemberEmailUnsubscribeRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        memberEmailUnsubscribe(requestParameters: CampaignEmailSubscriptionControllerApiMemberEmailUnsubscribeRequest, options?: RawAxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.memberEmailUnsubscribe(requestParameters.externalId, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @param {CampaignEmailSubscriptionControllerApiSetMemberEmailSubscriptionStatusRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        setMemberEmailSubscriptionStatus(requestParameters: CampaignEmailSubscriptionControllerApiSetMemberEmailSubscriptionStatusRequest, options?: RawAxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.setMemberEmailSubscriptionStatus(requestParameters.externalId, requestParameters.status, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for getMemberEmailSubscriptionStatus operation in CampaignEmailSubscriptionControllerApi.
 * @export
 * @interface CampaignEmailSubscriptionControllerApiGetMemberEmailSubscriptionStatusRequest
 */
export interface CampaignEmailSubscriptionControllerApiGetMemberEmailSubscriptionStatusRequest {
    /**
     *
     * @type {string}
     * @memberof CampaignEmailSubscriptionControllerApiGetMemberEmailSubscriptionStatus
     */
    readonly externalId: string
}

/**
 * Request parameters for memberEmailUnsubscribe operation in CampaignEmailSubscriptionControllerApi.
 * @export
 * @interface CampaignEmailSubscriptionControllerApiMemberEmailUnsubscribeRequest
 */
export interface CampaignEmailSubscriptionControllerApiMemberEmailUnsubscribeRequest {
    /**
     *
     * @type {string}
     * @memberof CampaignEmailSubscriptionControllerApiMemberEmailUnsubscribe
     */
    readonly externalId: string
}

/**
 * Request parameters for setMemberEmailSubscriptionStatus operation in CampaignEmailSubscriptionControllerApi.
 * @export
 * @interface CampaignEmailSubscriptionControllerApiSetMemberEmailSubscriptionStatusRequest
 */
export interface CampaignEmailSubscriptionControllerApiSetMemberEmailSubscriptionStatusRequest {
    /**
     *
     * @type {string}
     * @memberof CampaignEmailSubscriptionControllerApiSetMemberEmailSubscriptionStatus
     */
    readonly externalId: string

    /**
     *
     * @type {boolean}
     * @memberof CampaignEmailSubscriptionControllerApiSetMemberEmailSubscriptionStatus
     */
    readonly status: boolean
}

/**
 * CampaignEmailSubscriptionControllerApi - object-oriented interface
 * @export
 * @class CampaignEmailSubscriptionControllerApi
 * @extends {BaseAPI}
 */
export class CampaignEmailSubscriptionControllerApi extends BaseAPI {
    /**
     *
     * @param {CampaignEmailSubscriptionControllerApiGetMemberEmailSubscriptionStatusRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CampaignEmailSubscriptionControllerApi
     */
    public getMemberEmailSubscriptionStatus(requestParameters: CampaignEmailSubscriptionControllerApiGetMemberEmailSubscriptionStatusRequest, options?: RawAxiosRequestConfig) {
        return CampaignEmailSubscriptionControllerApiFp(this.configuration).getMemberEmailSubscriptionStatus(requestParameters.externalId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     *
     * @param {CampaignEmailSubscriptionControllerApiMemberEmailUnsubscribeRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CampaignEmailSubscriptionControllerApi
     */
    public memberEmailUnsubscribe(requestParameters: CampaignEmailSubscriptionControllerApiMemberEmailUnsubscribeRequest, options?: RawAxiosRequestConfig) {
        return CampaignEmailSubscriptionControllerApiFp(this.configuration).memberEmailUnsubscribe(requestParameters.externalId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     *
     * @param {CampaignEmailSubscriptionControllerApiSetMemberEmailSubscriptionStatusRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CampaignEmailSubscriptionControllerApi
     */
    public setMemberEmailSubscriptionStatus(requestParameters: CampaignEmailSubscriptionControllerApiSetMemberEmailSubscriptionStatusRequest, options?: RawAxiosRequestConfig) {
        return CampaignEmailSubscriptionControllerApiFp(this.configuration).setMemberEmailSubscriptionStatus(requestParameters.externalId, requestParameters.status, options).then((request) => request(this.axios, this.basePath));
    }
}

