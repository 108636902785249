/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


// May contain unused imports in some cases
// @ts-ignore
import type { GetMemberReferralsSummaryMetricZoneIdParameterRulesTransitionsInnerOffsetBefore } from './get-member-referrals-summary-metric-zone-id-parameter-rules-transitions-inner-offset-before';
// May contain unused imports in some cases
// @ts-ignore
import type { LocalTime } from './local-time';

/**
 *
 * @export
 * @interface GetMemberReferralsSummaryMetricZoneIdParameterRulesTransitionRulesInner
 */
export interface GetMemberReferralsSummaryMetricZoneIdParameterRulesTransitionRulesInner {
    /**
     *
     * @type {string}
     * @memberof GetMemberReferralsSummaryMetricZoneIdParameterRulesTransitionRulesInner
     */
    'month'?: GetMemberReferralsSummaryMetricZoneIdParameterRulesTransitionRulesInnerMonthEnum;
    /**
     *
     * @type {string}
     * @memberof GetMemberReferralsSummaryMetricZoneIdParameterRulesTransitionRulesInner
     */
    'timeDefinition'?: GetMemberReferralsSummaryMetricZoneIdParameterRulesTransitionRulesInnerTimeDefinitionEnum;
    /**
     *
     * @type {GetMemberReferralsSummaryMetricZoneIdParameterRulesTransitionsInnerOffsetBefore}
     * @memberof GetMemberReferralsSummaryMetricZoneIdParameterRulesTransitionRulesInner
     */
    'standardOffset'?: GetMemberReferralsSummaryMetricZoneIdParameterRulesTransitionsInnerOffsetBefore;
    /**
     *
     * @type {GetMemberReferralsSummaryMetricZoneIdParameterRulesTransitionsInnerOffsetBefore}
     * @memberof GetMemberReferralsSummaryMetricZoneIdParameterRulesTransitionRulesInner
     */
    'offsetBefore'?: GetMemberReferralsSummaryMetricZoneIdParameterRulesTransitionsInnerOffsetBefore;
    /**
     *
     * @type {GetMemberReferralsSummaryMetricZoneIdParameterRulesTransitionsInnerOffsetBefore}
     * @memberof GetMemberReferralsSummaryMetricZoneIdParameterRulesTransitionRulesInner
     */
    'offsetAfter'?: GetMemberReferralsSummaryMetricZoneIdParameterRulesTransitionsInnerOffsetBefore;
    /**
     *
     * @type {string}
     * @memberof GetMemberReferralsSummaryMetricZoneIdParameterRulesTransitionRulesInner
     */
    'dayOfWeek'?: GetMemberReferralsSummaryMetricZoneIdParameterRulesTransitionRulesInnerDayOfWeekEnum;
    /**
     *
     * @type {number}
     * @memberof GetMemberReferralsSummaryMetricZoneIdParameterRulesTransitionRulesInner
     */
    'dayOfMonthIndicator'?: number;
    /**
     *
     * @type {LocalTime}
     * @memberof GetMemberReferralsSummaryMetricZoneIdParameterRulesTransitionRulesInner
     */
    'localTime'?: LocalTime;
    /**
     *
     * @type {boolean}
     * @memberof GetMemberReferralsSummaryMetricZoneIdParameterRulesTransitionRulesInner
     */
    'midnightEndOfDay'?: boolean;
}

/**
    * @export
    * @enum {string}
    */
export enum GetMemberReferralsSummaryMetricZoneIdParameterRulesTransitionRulesInnerMonthEnum {
    JANUARY = 'JANUARY',
    FEBRUARY = 'FEBRUARY',
    MARCH = 'MARCH',
    APRIL = 'APRIL',
    MAY = 'MAY',
    JUNE = 'JUNE',
    JULY = 'JULY',
    AUGUST = 'AUGUST',
    SEPTEMBER = 'SEPTEMBER',
    OCTOBER = 'OCTOBER',
    NOVEMBER = 'NOVEMBER',
    DECEMBER = 'DECEMBER'
}
/**
    * @export
    * @enum {string}
    */
export enum GetMemberReferralsSummaryMetricZoneIdParameterRulesTransitionRulesInnerTimeDefinitionEnum {
    UTC = 'UTC',
    WALL = 'WALL',
    STANDARD = 'STANDARD'
}
/**
    * @export
    * @enum {string}
    */
export enum GetMemberReferralsSummaryMetricZoneIdParameterRulesTransitionRulesInnerDayOfWeekEnum {
    MONDAY = 'MONDAY',
    TUESDAY = 'TUESDAY',
    WEDNESDAY = 'WEDNESDAY',
    THURSDAY = 'THURSDAY',
    FRIDAY = 'FRIDAY',
    SATURDAY = 'SATURDAY',
    SUNDAY = 'SUNDAY'
}


